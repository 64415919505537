import React, { FunctionComponent } from "react"
import Hero from "../components/hero"
import AppStore from "../components/appstore"
import { graphql, PageProps } from "gatsby"
import Layout from "../layouts/layout"
import Feature from "../components/feature"
import {
  fixedWithIdentifier,
  fluidWithIdentifier,
} from "../helpers/image-helpers"
import { MetadataProps, SEOProps } from "../types/types"
import SEO from "../components/seo"
import heavySetIcon from "../images/heavyset-icon.svg"

const HeavySetPage: FunctionComponent<PageProps<MetadataProps & SEOProps>> = ({
  data,
}) => {
  const deviceFrame = fixedWithIdentifier("deviceFrame", data)
  return (
    <Layout>
      <SEO
        title="HeavySet · Gym Workout Log app for iOS"
        description="HeavySet is a gym workout log and tracker app for iOS
        that enables you to perform advanced routines including supersets, track
        1rm, personal records and progress over time."
        image={data.seoImage.childImageSharp.sizes.src}
        favIconSizes={[16, 32, 48, 96]}
        favIconTemplate="/images/heavyset/favicon-%s.png"
      />
      <Hero
        fluidImage={fluidWithIdentifier("hero", data)}
        logo={heavySetIcon}
        logoAlt="HeavySet Icon"
        heading="HeavySet - Gym Workout Log"
        lead="Workout tracker & gym log for bodybuilding and strength training."
      >
        <AppStore url={data.site.siteMetadata.urls.heavyset.appStore} />
      </Hero>

      <Feature
        title="Simple, Not Basic"
        screenshot={fixedWithIdentifier("workoutLogger", data)}
        frame={deviceFrame}
      >
        <p>
          No one likes fumbling around with their phone after a heavy set of
          deadlifts. HeavySet lets you log your sets with as little interaction
          as possible. Smart Values anticipate what you will add next.
        </p>
      </Feature>

      <Feature
        title="Personal Records"
        screenshot={fixedWithIdentifier("currentRecords", data)}
        frame={deviceFrame}
        altLayout={true}
      >
        <p>
          HeavySet keeps track of all your current rep records so you'll always
          know when you are getting stronger.
        </p>
      </Feature>

      <Feature
        title="Record History"
        screenshot={fixedWithIdentifier("recordHistory", data)}
        frame={deviceFrame}
      >
        <p>
          Knowing your current records is great. But HeavySet can provide extra
          motivation by showing all the records you've set since you starting
          logging your workouts.
        </p>
      </Feature>

      <Feature
        title="Routines"
        screenshot={fixedWithIdentifier("routines", data)}
        frame={deviceFrame}
        altLayout={true}
      >
        <p>
          HeavySet allows you to create advanced routines. These can include
          sets based on intensity and training max, supersets, giant sets and
          AMRAP sets.
        </p>
      </Feature>

      <Feature
        title="Organise and Share"
        screenshot={fixedWithIdentifier("organise", data)}
        frame={deviceFrame}
      >
        <p>
          In HeavySet it's easy to keep your routines organized. If you've
          created a routine your particularly proud of you can share it with
          your friends.
        </p>
      </Feature>

      <Feature
        title="Data Export"
        screenshot={fixedWithIdentifier("export", data)}
        frame={deviceFrame}
        altLayout={true}
      >
        <p>
          It's your data. HeavySet exports all routines, exercises and workouts
          in CSV format via Mail or to Dropbox, so you can use as you see fit.
        </p>
      </Feature>

      <Feature
        title="Import Existing Data"
        screenshot={fixedWithIdentifier("import", data)}
        frame={deviceFrame}
      >
        <p>
          The time you've invested in other apps has not been wasted. HeavySet
          can already import from most CSV files and has presets for Strong,
          StrongLifts and others.
        </p>
      </Feature>
    </Layout>
  )
}

export default HeavySetPage

export const query = graphql`
  fragment fixedScreenshot on File {
    childImageSharp {
      fixed(width: 243, height: 432) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  fragment fluidBackground on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  query HeavySetQuery {
    site {
      siteMetadata {
        title
        emails {
          privacy
          support
        }
        urls {
          support
          seconds {
            appStore
            googlePlay
          }
          heavyset {
            appStore
          }
        }
      }
    }
    seoImage: file(relativePath: { eq: "images/seconds/promo.jpg" }) {
      childImageSharp {
        sizes {
          src
        }
      }
    }
    hero: file(
      relativePath: { eq: "images/heavyset/heavyset-background.jpg" }
    ) {
      ...fluidBackground
    }
    deviceFrame: file(
      relativePath: { eq: "images/device-frames/iphone-6-white.png" }
    ) {
      childImageSharp {
        fixed(width: 300, height: 582) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    currentRecords: file(
      relativePath: { eq: "images/heavyset/screenshots/current-records.png" }
    ) {
      ...fixedScreenshot
    }
    export: file(
      relativePath: { eq: "images/heavyset/screenshots/export.png" }
    ) {
      ...fixedScreenshot
    }
    import: file(
      relativePath: { eq: "images/heavyset/screenshots/import.png" }
    ) {
      ...fixedScreenshot
    }
    organise: file(
      relativePath: { eq: "images/heavyset/screenshots/organise.png" }
    ) {
      ...fixedScreenshot
    }
    recordHistory: file(
      relativePath: { eq: "images/heavyset/screenshots/record-history.png" }
    ) {
      ...fixedScreenshot
    }
    routines: file(
      relativePath: { eq: "images/heavyset/screenshots/routines.png" }
    ) {
      ...fixedScreenshot
    }
    workoutLogger: file(
      relativePath: { eq: "images/heavyset/screenshots/workout-logger.png" }
    ) {
      ...fixedScreenshot
    }
  }
`
